import { connect } from 'react-redux'
import { INSERT_PRODUCT, REQUEST_PRODUCT, UPDATE_PRODUCT } from '../ducks/products'

import ProductForm from '../components/ProductForm'

const mapStateToProps = state => {
  return {
    loading: state.products.loading,
    updating: state.products.updating,
    productsById: state.products.byId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestProduct: productID => dispatch(REQUEST_PRODUCT(productID)),
    insert: ({ product, redirect }) => dispatch(INSERT_PRODUCT({ product, redirect })),
    update: ({ product, productID, redirect }) => dispatch(UPDATE_PRODUCT({ product, productID, redirect }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm)
