import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'

import styles from './UserList.module.scss'
import Table from '../Table'
import Button from '../Button'
import Popup from '../Popup'

const NameDisplay = ({ firstName, lastName, companyName }) => firstName ? `${firstName} ${lastName}` : companyName

const LastLoginDisplay = ({ lastLogin }) => <span title={moment(lastLogin).format('DD/MM/YY HH:mm')}>{moment(lastLogin).fromNow()}</span>

const UserList = ({ loading, requestUsers, resetPassword, passwordResetLoading, users }) => {
  const RowAction = ({ _id, email }) => (
    <div className={styles.rowActions}>
      <Link to={`/users/${_id}`}>
        <Button loading={passwordResetLoading} onClick={() => false} outline size='small'>View</Button>
      </Link>
      <Popup
        content={(
          <Button loading={passwordResetLoading} onClick={() => resetPassword(email)} outline size='small'>Send Password Reset</Button>
        )}
      >
        <Button size='small' outline icon='ChevronDown' />
      </Popup>
    </div>
  )
  RowAction.propTypes = {
    _id: PropTypes.string,
    email: PropTypes.string
  }
  const cols = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: '_id',
      render: NameDisplay
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Last logged in',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      render: LastLoginDisplay
    },
    {
      title: '',
      key: 'actions',
      render: RowAction
    }
  ]
  useEffect(() => {
    requestUsers()
  }, [requestUsers])
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <h1 className={styles.title}>Users</h1>
      </div>
      <div className={styles.tableWrap}>
        <Table
          defaultSortIndex='firstName'
          columns={cols}
          data={users}
          loading={loading}
        />
      </div>
    </div>
  )
}

UserList.defaultProps = {
  users: []
}

UserList.propTypes = {
  loading: PropTypes.bool,
  passwordResetLoading: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
  resetPassword: PropTypes.func,
  requestUsers: PropTypes.func
}
LastLoginDisplay.propTypes = {
  lastLogin: PropTypes.string
}
NameDisplay.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyname: PropTypes.string
}

export default UserList
