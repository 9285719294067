import { connect } from 'react-redux'
import { REQUEST_USER } from '../ducks/users'
import UserView from '../components/UserView'

const mapStateToProps = state => {
  return {
    loading: state.users.loading,
    usersById: state.users.byId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestUser: userID => dispatch(REQUEST_USER(userID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserView)
