import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { extraPropType } from '../../types'
import styles from './ProductForm.module.scss'
import Form, { Field } from '../Form'
import Input from '../Input'
import Checkbox from '../Checkbox'
import Button from '../Button'

const ProductForm = ({ loading, updating, requestProduct, productsById, insert, update, history }) => {
  const { productID } = useParams()

  useEffect(() => {
    if (productID) {
      requestProduct(productID)
    }
  }, [productID, requestProduct])

  if (loading || (productID && !productsById[productID])) return ''

  const product = productsById[productID] || {}
  const redirect = () => history.push('/products')

  const onSubmit = ({ values, valid }) => {
    if (!valid) return
    values.inStock = !!values.inStock
    values.hasSubscription = !!values.hasSubscription
    values.generateInstall = !!values.generateInstall
    values.price = values.price * 100
    values.monthlyCost = values.monthlyCost * 100
    if (productID) {
      update({ product: values, productID, redirect })
    } else {
      insert({ product: values, redirect })
    }
  }

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{productID ? 'Updating' : 'Add a new'} product</h1>
      <Form onSubmit={onSubmit} className={styles.form}>
        <Field label="Product Name" required>
          <Input
            type="text"
            size="large"
            placeholder="What should your product be called?"
            name="name"
            defaultValue={productID ? product.name : ''} />
        </Field>
        <Field label="Product Price" required validations={['isCurrency']} >
          <Input
            type="tel"
            placeholder="e.g. 149.99"
            name="price"
            tabBefore='£'
            defaultValue={productID && product.price ? product.price / 100 : ''} />
        </Field>
        <Field label="Description" required>
          <Input
            type="textarea"
            placeholder="Describe the product..."
            name="description"
            defaultValue={productID ? product.description : ''} />
        </Field>
        <Field label="Installation Required?" >
          <Checkbox name="generateInstall" defaultChecked={productID && product.generateInstall}>
            <span className={styles.hint}>Generate an installation task when selected</span>
          </Checkbox>
        </Field>
        <Field label="In Stock" >
          <Checkbox name="inStock" defaultChecked={productID && product.inStock}>
            <span className={styles.hint}>This product is available for purchase</span>
          </Checkbox>
        </Field>
        <Field label="Monthly Payments?" >
          <Checkbox name="hasSubscription" defaultChecked={productID && product.hasSubscription}>
            <span className={styles.hint}>This product incurs a monthly cost</span>
          </Checkbox>
        </Field>
        <Field label="Monthly Price" required validations={['isCurrency']} >
          <Input
            type="tel"
            placeholder="e.g. 19.99"
            name="monthlyCost"
            tabBefore='£'
            defaultValue={productID && product.monthlyCost ? product.monthlyCost / 100 : ''} />
        </Field>
        <Button loading={updating} type="submit">{productID ? 'Save Changes' : 'Save product'}</Button>
      </Form>
    </div>
  )
}

ProductForm.propTypes = {
  loading: PropTypes.bool,
  productsById: PropTypes.objectOf(extraPropType),
  updating: PropTypes.bool,
  requestProduct: PropTypes.func,
  requestProducts: PropTypes.func,
  insert: PropTypes.func,
  update: PropTypes.func
}

export default ProductForm
