/* global window */
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { LOGIN_RESTORED } from './ducks/auth'

import './styles/global.scss'
import Layout from './containers/Layout'
import PrivateRoute from './containers/PrivateRoute'
import Login from './containers/Login'
import AdminDashboard from './containers/AdminDashboard'
import PlanList from './containers/PlanList'
import PlanForm from './containers/PlanForm'
import ProductList from './containers/ProductList'
import ProductForm from './containers/ProductForm'
import InstallationList from './containers/InstallationList'
import ViewInstallation from './containers/ViewInstallation'
import UserView from './containers/UserView'
import UserList from './containers/UserList'
import OrderList from './containers/OrderList'
import ViewOrder from './containers/ViewOrder'
import ResetPassword from './containers/ResetPassword'
import RequestReset from './containers/RequestReset'

const mapDispatchToProps = dispatch => {
  return {
    restoreSession: ({ id, token }) => dispatch(LOGIN_RESTORED({ id, token }))
  }
}

class AppRoutes extends React.Component {
  constructor (props) {
    super(props)
    const currentUser = window.localStorage.getItem('currentUser')
    if (currentUser) {
      this.props.restoreSession(JSON.parse(currentUser))
    }
  }

  render () {
    return (
      <Layout>
        <Switch>
          <PrivateRoute path='/' exact component={AdminDashboard} />
          <PrivateRoute path='/plans' exact component={PlanList} />
          <PrivateRoute path='/plans/create' exact component={PlanForm} />
          <PrivateRoute path='/plans/edit/:planID' component={PlanForm} />
          <PrivateRoute path='/products' exact component={ProductList} />
          <PrivateRoute path='/products/create' exact component={ProductForm} />
          <PrivateRoute path='/products/edit/:productID' component={ProductForm} />
          <PrivateRoute path='/installations' exact component={InstallationList} />
          <PrivateRoute path='/users/:userID' component={UserView} />
          <PrivateRoute path='/installations/edit/:installationID' component={ViewInstallation} />
          <PrivateRoute path='/users' exact component={UserList} />
          <PrivateRoute path='/orders' exact component={OrderList} />
          <PrivateRoute path='/orders/:orderID' component={ViewOrder} />
          <Route path='/reset' component={ResetPassword} />
          <Route path='/forgot-password' component={RequestReset} />
          <Route path='/login' component={Login} />
        </Switch>
      </Layout>
    )
  }
}

AppRoutes.propTypes = {
  restoreSession: PropTypes.func
}

export default connect(null, mapDispatchToProps)(AppRoutes)
