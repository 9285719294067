import { connect } from 'react-redux'
import { INSERT_PLAN, REQUEST_PLAN, UPDATE_PLAN } from '../ducks/plans'
import { REQUEST_PRODUCTS } from '../ducks/products'
import PlanForm from '../components/PlanForm'

const mapStateToProps = state => {
  return {
    loading: state.plans.loading || state.products.loading,
    updating: state.plans.updating,
    plansById: state.plans.byId,
    productsById: state.products.byId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestPlan: planID => dispatch(REQUEST_PLAN(planID)),
    requestProducts: () => dispatch(REQUEST_PRODUCTS()),
    insert: ({ plan, redirect }) => dispatch(INSERT_PLAN({ plan, redirect })),
    update: ({ plan, planID, redirect }) => dispatch(UPDATE_PLAN({ plan, planID, redirect }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanForm)
