import { connect } from 'react-redux'
import { REQUEST_PRODUCTS } from '../ducks/products'
import ProductList from '../components/ProductList'

const mapStateToProps = state => {
  return {
    loading: state.products.loading,
    products: Object.values(state.products.byId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestProducts: () => dispatch(REQUEST_PRODUCTS())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
