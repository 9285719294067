import { createSlice } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'
import { SHOW_MESSAGE } from './messages'
import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'plans',
  initialState: {
    error: false,
    loading: true,
    updating: false,
    deleting: false,
    byId: {}
  },
  reducers: {
    REQUEST_PLAN (state, { payload }) {
      state.loading = true
    },
    REQUEST_PLANS (state, { payload }) {
      state.loading = true
    },
    REQUEST_PLAN_SUCCESS (state, { payload }) {
      if (payload && payload._id) {
        state.byId[payload._id] = payload
      }
      state.loading = false
    },
    REQUEST_PLANS_SUCCESS (state, { payload }) {
      state.byId = keyBy(payload, '_id')
      state.loading = false
    },
    INSERT_PLAN (state, { payload }) {
      state.updating = true
    },
    UPDATE_PLAN (state, { payload }) {
      state.updating = true
    },
    DELETE_PLAN (state, { payload }) {
      state.deleting = true
    },
    UPDATE_PLAN_SUCCESS (state, { payload }) {
      state.updating = false
      state.byId[payload._id] = payload
    },
    DELETE_PLAN_SUCCESS (state, { payload }) {
      state.deleting = false
      delete state.byId[payload]
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    }
  }
})

function * onRequestPlan ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/plans/${payload}`
    )
    yield put(actions.REQUEST_PLAN_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestPlans ({ payload }) {
  try {
    const { data } = yield call(
      API.get, '/plans'
    )
    yield put(actions.REQUEST_PLANS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onInsertPlan ({ payload }) {
  try {
    const { data } = yield call(
      API.post, '/plans', payload.plan
    )
    yield put(actions.UPDATE_PLAN_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Plan created' }))
    yield call(payload.redirect)
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onUpdatePlan ({ payload }) {
  try {
    const { data } = yield call(
      API.patch, `/plans/${payload.planID}`, payload.plan
    )
    yield put(actions.UPDATE_PLAN_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Plan updated' }))
    yield call(payload.redirect)
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onDeletePlan ({ payload }) {
  try {
    const { data } = yield call(
      API.delete, `/plans/${payload}`, payload
    )
    yield put(actions.DELETE_PLAN_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Plan deleted' }))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('plans/REQUEST_PLAN', onRequestPlan)
  yield takeLatest('plans/REQUEST_PLANS', onRequestPlans)
  yield takeLatest('plans/INSERT_PLAN', onInsertPlan)
  yield takeLatest('plans/UPDATE_PLAN', onUpdatePlan)
  yield takeLatest('plans/DELETE_PLAN', onDeletePlan)
  yield takeLatest('plans/REQUEST_FAILED', onRequestFailed)
}

export const { REQUEST_PLAN, REQUEST_PLANS, INSERT_PLAN, UPDATE_PLAN, DELETE_PLAN } = actions

export default reducer
