import { connect } from 'react-redux'
import { REQUEST_PLANS } from '../ducks/plans'
import PlanList from '../components/PlanList'

const mapStateToProps = state => {
  return {
    loading: state.plans.loading,
    plans: Object.values(state.plans.byId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestPlans: () => dispatch(REQUEST_PLANS())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanList)
