import { createSlice } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'users',
  initialState: {
    error: false,
    loading: true,
    submitting: false,
    me: {},
    byId: {}
  },
  reducers: {
    REQUEST_ME (state, { payload }) {
      state.loading = true
    },
    REQUEST_USER (state, { payload }) {
      state.loading = true
    },
    REQUEST_USERS (state, { payload }) {
      state.loading = true
    },
    REQUEST_ME_SUCCESS (state, { payload }) {
      state.loading = false
      if (payload && payload._id) {
        state.me = payload
      }
    },
    REQUEST_USER_SUCCESS (state, { payload }) {
      state.loading = false
      if (payload && payload._id) {
        state.byId[payload._id] = payload
      }
    },
    REQUEST_USERS_SUCCESS (state, { payload }) {
      state.loading = false
      state.byId = keyBy(payload, '_id')
    },
    UPDATE_USER (state, { payload }) {
      state.submitting = true
    },
    UPDATE_USER_SUCCESS (state, { payload }) {
      state.submitting = false
      if (payload && payload._id) {
        state.byId[payload._id] = payload
      }
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.submitting = false
      state.loading = false
    }
  }
})

function * onRequestMe ({ payload }) {
  try {
    const { data } = yield call(
      API.get, '/auth/me'
    )
    yield put(actions.REQUEST_ME_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestUser ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/user/${payload}`
    )
    yield put(actions.REQUEST_USER_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onUpdateUser ({ payload }) {
  try {
    const { data } = yield call(
      API.patch, `/user/${payload._id}`, payload
    )
    yield put(actions.UPDATE_USER_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Contact Information Updated' }))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestUsers ({ payload }) {
  try {
    const { data } = yield call(
      API.get, '/users', {
        params: payload
      }
    )
    yield put(actions.REQUEST_USERS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('users/REQUEST_ME', onRequestMe)
  yield takeLatest('users/REQUEST_USER', onRequestUser)
  yield takeLatest('users/UPDATE_USER', onUpdateUser)
  yield takeLatest('users/REQUEST_USERS', onRequestUsers)
  yield takeLatest('users/REQUEST_FAILED', onRequestFailed)
}

export const { REQUEST_ME, REQUEST_USER, REQUEST_USERS, UPDATE_USER } = actions

export default reducer
