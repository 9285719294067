import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FiShoppingCart, FiActivity, FiPackage, FiLogOut, FiUsers, FiArchive, FiCalendar } from 'react-icons/fi'
import navStyles from './Nav.module.scss'

const Nav = ({ logout }) => (
  <nav className={navStyles.root}>
    <Link to="/">
      <div className={navStyles.logo}>
        <img src="/logo_color.png" alt="logo" />
      </div>
    </Link>
    <h3 className={navStyles.adminText}>Business<br/>Management</h3>
    <ul className={navStyles.links}>
      <NavLink to="/" exact activeClassName={navStyles.activeLink}>
        <li className={navStyles.link}>
          <FiActivity /> <span>Business Overview</span>
        </li>
      </NavLink>
      <NavLink to="/plans" activeClassName={navStyles.activeLink}>
        <li className={navStyles.link}>
          <FiPackage /> <span>Plans &amp; Pricing</span>
        </li>
      </NavLink>
      <NavLink to="/products" activeClassName={navStyles.activeLink}>
        <li className={navStyles.link}>
          <FiArchive /> <span>Products &amp; Extras</span>
        </li>
      </NavLink>
      <NavLink to="/installations" activeClassName={navStyles.activeLink}>
        <li className={navStyles.link}>
          <FiCalendar /> <span>Installations</span>
        </li>
      </NavLink>
      <NavLink to="/users" activeClassName={navStyles.activeLink}>
        <li className={navStyles.link}>
          <FiUsers /> <span>User Accounts</span>
        </li>
      </NavLink>
      <NavLink to="/orders" activeClassName={navStyles.activeLink}>
        <li className={navStyles.link}>
          <FiShoppingCart /> <span>Order Management</span>
        </li>
      </NavLink>
      <span onClick={logout} style={{ cursor: 'pointer', marginTop: 'auto' }}>
        <li className={navStyles.link}>
          <FiLogOut /> <span>Logout</span>
        </li>
      </span>
    </ul>
  </nav>
)

Nav.propTypes = {
  logout: PropTypes.func
}

export default Nav
