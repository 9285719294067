import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'

import styles from './InstallationList.module.scss'
import { installationPropType } from '../../types'
import StatusDisplay from '../StatusDisplay'
import Table from '../Table'
import Button from '../Button'

const NameDisplay = ({ customer: { firstName, lastName, companyName } }) => firstName ? `${firstName} ${lastName}` : companyName

const PlanDisplay = ({ subscription: { plan: { name } } }) => name

const DateDisplay = ({ date }) => date ? moment(date).format('Do MMM YYYY HH:mm') : 'Not booked'

const RowAction = ({ _id }) => <Link style={{ cssFloat: 'right' }} to={`/installations/edit/${_id}`}><Button outline size='small'>View Details</Button></Link>

const InstallationList = ({ loading, requestInstallations, installations }) => {
  const cols = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: NameDisplay
    },
    {
      title: 'Plan',
      dataIndex: 'subscription',
      key: 'subscription',
      render: PlanDisplay
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: DateDisplay
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: StatusDisplay
    },
    {
      title: '',
      key: 'actions',
      render: RowAction
    }
  ]
  useEffect(() => {
    requestInstallations()
  }, [requestInstallations])
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <h1 className={styles.title}>Installations &amp; Extras</h1>

      </div>
      <div className={styles.tableWrap}>
        <Table
          defaultSortIndex='status'
          defaultSortDir='desc'
          columns={cols}
          data={installations}
          loading={loading}
          noContent={{
            title: 'No installations',
            text: 'Installations will appear here when they are needed',
            image: '/network_installer.png'
          }}
        />
      </div>
    </div>
  )
}

InstallationList.defaultProps = {
  installations: []
}

InstallationList.propTypes = {
  loading: PropTypes.bool,
  installations: installationPropType,
  requestInstallations: PropTypes.func
}

StatusDisplay.propTypes = {
  status: PropTypes.string
}

RowAction.propTypes = {
  _id: PropTypes.string
}

export default InstallationList
