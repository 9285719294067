import { connect } from 'react-redux'
import { REQUEST_INSTALLATION, UPDATE_INSTALLATION } from '../ducks/installations'

import ViewInstallation from '../components/ViewInstallation'

const mapStateToProps = state => {
  return {
    loading: state.installations.loading,
    updating: state.installations.updating,
    installationsById: state.installations.byId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestInstallation: installationID => dispatch(REQUEST_INSTALLATION(installationID)),
    update: payload => dispatch(UPDATE_INSTALLATION(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInstallation)
