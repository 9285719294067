import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './AdminDashboard.module.scss'
import { subscriptionPropType, paymentPropType } from '../../types'

import Overview from '../Overview'
import RecentCustomers from '../RecentCustomers'
import BillingIssues from '../BillingIssues'
import CustomerChart from '../CustomerChart'

const AdminDashboard = ({ resolvePayment, getStats, loading, overview, recentSignups, customersPerMonth, billingIssues }) => {
  useEffect(() => {
    getStats()
  }, [getStats])
  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>Business Overview</h1>
      <Overview
        totalCustomers={overview.totalCustomers}
        monthlyRevenue={overview.monthlyRevenue}
        outstandingInstallations={overview.outstandingInstallations}
        loading={loading.overview}
      />
      <CustomerChart data={customersPerMonth} />
      <div className={styles.row}>
        <RecentCustomers loading={loading.recentSignups} signups={recentSignups} />
        <BillingIssues issues={billingIssues} loading={loading.billingIssues} resolve={resolvePayment} />
      </div>
    </div>
  )
}

AdminDashboard.propTypes = {
  getStats: PropTypes.func,
  resolvePayment: PropTypes.func,
  loading: PropTypes.shape({
    overview: PropTypes.bool,
    recentSignups: PropTypes.bool,
    customersPerMonth: PropTypes.bool,
    billingIssues: PropTypes.bool
  }),
  overview: PropTypes.shape({
    totalCustomers: PropTypes.number,
    monthlyRevenue: PropTypes.number,
    outstandingInstallations: PropTypes.number
  }),
  recentSignups: PropTypes.arrayOf(subscriptionPropType),
  customersPerMonth: PropTypes.arrayOf(PropTypes.object),
  billingIssues: PropTypes.arrayOf(paymentPropType)
}

export default AdminDashboard
