import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FiCheck, FiX } from 'react-icons/fi'

import styles from './ProductList.module.scss'
import { extraPropType } from '../../types'
import Table from '../Table'
import Button from '../Button'

const InStockIndicator = ({ inStock }) => inStock
  ? <FiCheck className={styles.check} />
  : <FiX className={styles.cross} />

const CostDisplay = ({ price }) => `£${price / 100}`

const RowAction = ({ _id }) => <Link style={{ cssFloat: 'right' }} to={`/products/edit/${_id}`}><Button outline size='small'>Edit</Button></Link>

const ProductList = ({ loading, requestProducts, products }) => {
  const cols = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'In Stock',
      dataIndex: 'inStock',
      key: 'inStock',
      render: InStockIndicator
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: CostDisplay
    },
    {
      title: '',
      key: 'actions',
      render: RowAction
    }
  ]
  useEffect(() => {
    requestProducts()
  }, [requestProducts])
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <h1 className={styles.title}>Products &amp; Extras</h1>
        <Link to={'/products/create'}><Button >Create New</Button></Link>
      </div>
      <div className={styles.tableWrap}>
        <Table
          defaultSortIndex='monthlyCost'
          columns={cols}
          data={products}
          loading={loading}
          noContent={{
            title: "There's nothing here",
            text: 'Get started by adding some products',
            image: '/empty_payments.svg'
          }}
        />
      </div>
    </div>
  )
}

ProductList.defaultProps = {
  products: []
}

ProductList.propTypes = {
  loading: PropTypes.bool,
  products: extraPropType,
  requestProducts: PropTypes.func
}

InStockIndicator.propTypes = {
  inStock: PropTypes.bool
}

CostDisplay.propTypes = {
  price: PropTypes.number
}

RowAction.propTypes = {
  _id: PropTypes.string
}

export default ProductList
