import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FiCheck, FiX } from 'react-icons/fi'

import styles from './PlanList.module.scss'
import { planPropType } from '../../types'
import Table from '../Table'
import Button from '../Button'

const ActiveIndicator = ({ inactive }) => inactive
  ? <FiX className={styles.cross} />
  : <FiCheck className={styles.check} />

const NetworkTypes = ({ networkTypes }) => networkTypes.join(', ')

const CostDisplay = ({ monthlyCost }) => `£${monthlyCost / 100}`

const RowAction = ({ _id }) => <Link style={{ cssFloat: 'right' }} to={`/plans/edit/${_id}`}><Button outline size='small'>Edit</Button></Link>

const PlanList = ({ loading, requestPlans, plans }) => {
  const cols = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Active',
      dataIndex: 'inactive',
      key: 'inactive',
      render: ActiveIndicator
    },
    {
      title: 'Networks',
      dataIndex: 'networkTypes',
      key: 'networkTypes',
      render: NetworkTypes
    },
    {
      title: 'Monthly Price',
      dataIndex: 'monthlyCost',
      key: 'monthlyCost',
      render: CostDisplay
    },
    {
      title: '',
      key: 'actions',
      render: RowAction
    }
  ]
  useEffect(() => {
    requestPlans()
  }, [requestPlans])
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <h1 className={styles.title}>Plans &amp; Pricing</h1>
        <Link to={'/plans/create'}><Button >Create New</Button></Link>
      </div>
      <div className={styles.tableWrap}>
        <Table
          defaultSortIndex='monthlyCost'
          columns={cols}
          data={plans}
          loading={loading}
          noContent={{
            title: "There's nothing here",
            text: 'Get started by adding some plans',
            image: '/empty_payments.svg'
          }}
        />
      </div>
    </div>
  )
}

PlanList.defaultProps = {
  plans: []
}

PlanList.propTypes = {
  loading: PropTypes.bool,
  plans: planPropType,
  requestPlans: PropTypes.func
}

ActiveIndicator.propTypes = {
  inactive: PropTypes.bool
}

NetworkTypes.propTypes = {
  networkTypes: PropTypes.arrayOf(PropTypes.oneOf([
    'FTTP',
    'FTTC',
    'ADSL2PLUS',
    'ADSL2PLUSANNEXM',
    'GFAST',
    'SOGEA'
  ]))
}

CostDisplay.propTypes = {
  monthlyCost: PropTypes.number
}

RowAction.propTypes = {
  _id: PropTypes.string
}

export default PlanList
