import { createSlice } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'
import { SHOW_MESSAGE } from './messages'
import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'installations',
  initialState: {
    error: false,
    loading: true,
    updating: false,
    deleting: false,
    byId: {}
  },
  reducers: {
    REQUEST_INSTALLATION (state, { payload }) {
      state.loading = true
    },
    REQUEST_INSTALLATIONS (state, { payload }) {
      state.loading = true
    },
    REQUEST_INSTALLATION_SUCCESS (state, { payload }) {
      if (payload && payload._id) {
        state.byId[payload._id] = payload
      }
      state.loading = false
    },
    REQUEST_INSTALLATIONS_SUCCESS (state, { payload }) {
      state.byId = keyBy(payload, '_id')
      state.loading = false
    },
    INSERT_INSTALLATION (state, { payload }) {
      state.updating = true
    },
    UPDATE_INSTALLATION (state, { payload }) {
      state.updating = true
    },
    DELETE_INSTALLATION (state, { payload }) {
      state.deleting = true
    },
    UPDATE_INSTALLATION_SUCCESS (state, { payload }) {
      state.updating = false
      state.byId[payload._id] = payload
    },
    DELETE_INSTALLATION_SUCCESS (state, { payload }) {
      state.deleting = false
      delete state.byId[payload]
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    }
  }
})

function * onRequestInstallation ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/installation/${payload}`
    )
    yield put(actions.REQUEST_INSTALLATION_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestInstallations ({ payload }) {
  try {
    const { data } = yield call(
      API.get, '/installations'
    )
    yield put(actions.REQUEST_INSTALLATIONS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onInsertInstallation ({ payload }) {
  try {
    const { data } = yield call(
      API.post, '/installations', payload.installation
    )
    yield put(actions.UPDATE_INSTALLATION_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Installation created' }))
    yield call(payload.redirect)
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onUpdateInstallation ({ payload }) {
  try {
    const { data } = yield call(
      API.patch, `/installation/${payload._id}`, payload
    )
    yield put(actions.UPDATE_INSTALLATION_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Installation updated' }))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onDeleteInstallation ({ payload }) {
  try {
    const { data } = yield call(
      API.delete, `/installation/${payload}`, payload
    )
    yield put(actions.DELETE_INSTALLATION_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Installation deleted' }))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('installations/REQUEST_INSTALLATION', onRequestInstallation)
  yield takeLatest('installations/REQUEST_INSTALLATIONS', onRequestInstallations)
  yield takeLatest('installations/INSERT_INSTALLATION', onInsertInstallation)
  yield takeLatest('installations/UPDATE_INSTALLATION', onUpdateInstallation)
  yield takeLatest('installations/DELETE_INSTALLATION', onDeleteInstallation)
  yield takeLatest('installations/REQUEST_FAILED', onRequestFailed)
}

export const { REQUEST_INSTALLATION, REQUEST_INSTALLATIONS, INSERT_INSTALLATION, UPDATE_INSTALLATION, DELETE_INSTALLATION } = actions

export default reducer
