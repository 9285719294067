import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { installationPropType } from '../../types'
import styles from './InstallationDetails.module.scss'

const InstallationDetails = ({ installation }) => {
  return (
    <div className={styles.root}>
      <div className={styles.details}>
        {installation.date && <span className={styles.heading}>Date booked</span>}
        {installation.date && <span className={styles.value}>{moment(installation.date).format('Do MMMM \\at h:mma')}</span>}
        <span className={styles.heading}>Customer name</span>
        <span className={styles.value}>{installation.customer.firstName ? `${installation.customer.firstName} ${installation.customer.lastName}` : installation.customer.companyName}</span>
        <span className={styles.heading}>Customer email</span>
        <span className={styles.value}>{installation.customer.email}</span>
        <span className={styles.heading}>Customer telephone</span>
        <span className={styles.value}>{installation.customer.phone || 'None given'}</span>
        <span className={styles.heading}>Installation address</span>
        <address className={styles.address}>
          {installation.address.line1 && <span>{installation.address.line1}</span>}
          {installation.address.line2 && <span>{installation.address.line2}</span>}
          {installation.address.line3 && <span>{installation.address.line3}</span>}
          {installation.address.city && <span>{installation.address.city}</span>}
          {installation.address.region && <span>{installation.address.region}</span>}
          {installation.address.postcode && <span>{installation.address.postcode}</span>}
        </address>
      </div>
    </div>
  )
}

InstallationDetails.propTypes = {
  installation: installationPropType
}

export default InstallationDetails
