import { connect } from 'react-redux'
import { RESOLVE_PAYMENT, REQUEST_OVERVIEW, REQUEST_RECENT_SIGNUPS, REQUEST_BILLING_ISSUES, REQUEST_MONTHLY_SIGNUPS } from '../ducks/stats'
import AdminDashboard from '../components/AdminDashboard'

const mapStateToProps = state => {
  return {
    loading: state.stats.loading,
    overview: state.stats.overview,
    recentSignups: state.stats.recentSignups,
    customersPerMonth: state.stats.customersPerMonth,
    billingIssues: state.stats.billingIssues
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getStats: () => {
      dispatch(REQUEST_OVERVIEW())
      dispatch(REQUEST_RECENT_SIGNUPS())
      dispatch(REQUEST_BILLING_ISSUES())
      dispatch(REQUEST_MONTHLY_SIGNUPS())
    },
    resolvePayment: paymentID => dispatch(RESOLVE_PAYMENT(paymentID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
