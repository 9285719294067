import React, { useEffect, Fragment } from 'react'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import StatusDisplay from '../StatusDisplay'
import OrderPaymentList from '../../containers/OrderPaymentList'

import styles from './ViewOrder.module.scss'

const ViewOrder = ({ ordersById, requestOrder, loading }) => {
  const { orderID } = useParams()
  const order = ordersById[orderID]

  useEffect(() => {
    if (orderID) {
      requestOrder(orderID)
    }
  }, [orderID, requestOrder])

  if (loading || !order) return ''
  const { customer = { noCustomer: true, address: {} }, plan, products } = order

  return (
    <div className={styles.root}>
      <StatusDisplay status={order.status} />
      <h1 className={styles.name}>Order #{order._id}</h1>
      <div className={styles.row}>
        {!customer.noCustomer && <div className={styles.card}>
          <h2 className={styles.heading}>Customer Details</h2>
          <h3 className={styles.subheading}>
            {customer.companyName || `${customer.firstName} ${customer.lastName}`}
          </h3>
          <p className={styles.cardValue}>
            {customer.email}
          </p>
          <p className={styles.cardValue}>
            {customer.phone}
          </p>
          <address className={styles.cardValue}>
            {customer.address.line1},<br/>
            {customer.address.line2 && (<Fragment>{customer.address.line2},<br /></Fragment>)}
            {customer.address.line3 && (<Fragment>{customer.address.line3},<br /></Fragment>)}
            {customer.address.city},<br/>
            {customer.address.region && (<Fragment>{customer.address.region},<br/></Fragment>)}
            {customer.address.postcode}
          </address>
        </div>}
        <div className={styles.card}>
          <h2 className={styles.heading}>Plan Details</h2>
          <h3 className={styles.subheading}>
            {plan.name}
          </h3>
          <p className={styles.cardValue}>
            £{plan.monthlyCost / 100}/month
          </p>
          <p className={styles.cardValue} style={{ marginTop: '1.5rem' }}>
            {!plan.requiresInstall && 'No '}Installation Required
          </p>
          {plan.requiresInstall && <p className={styles.cardValue}>
            £{plan.installCost / 100} installation cost
          </p>}
          <h4 className={styles.subSubHeading}>Extras</h4>
          <ul className={styles.cardList}>
            {products.map(product => (
              <li>{product.name}<br/>{product.price && `£${product.price / 100}`}{product.price && product.hasSubscription && ' + '}{product.hasSubscription && `£${product.monthlyCost / 100}/month`}</li>
            ))}
          </ul>
        </div>
      </div>
      <OrderPaymentList subscriptionID={orderID} />
    </div>
  )
}

export default ViewOrder
