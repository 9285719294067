import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import * as icons from 'react-icons/io'

import Field from '../Form/Field'
import Input from '../Input'
import Button from '../Button'
import styles from './PlanForm.module.scss'

const IconDisplay = ({ Icon, iconName, selected, select }) => {
  const classes = classnames(
    styles.iconDisplay,
    { [styles.selectedIcon]: selected }
  )
  return (
    <div className={classes} onClick={select}>
      <Icon />
      <span>{iconName.replace(/IoMd|IoIos/, '')}</span>
    </div>
  )
}

IconDisplay.propTypes = {
  Icon: PropTypes.any,
  selected: PropTypes.bool,
  iconName: PropTypes.string,
  select: PropTypes.func
}

const FeatureInput = ({ defaultDescription, defaultIcon, saveFeature, cancel }) => {
  const [selectedIcon, selectIcon] = useState(defaultIcon || 'IoIosStar')
  const [description, setDescription] = useState(defaultDescription || '')
  return (
    <div className={styles.featureInput}>
      <Field label="Description" required onChange={e => setDescription(e.target.value)}>
        <Input
          type="text"
          placeholder="e.g. No usage limits"
          name="description"
          defaultValue={defaultDescription || ''} />
      </Field>
      <p className={styles.hint}>(use CTRL/CMD+F to search icons)</p>
      <div className={styles.iconPicker}>
        {Object.entries(icons).map(([iconName, Icon]) => (
          <IconDisplay key={iconName} Icon={Icon} iconName={iconName} select={() => selectIcon(iconName)} selected={iconName === selectedIcon} />
        ))}
      </div>
      <div className={styles.featureInputActions}>
        <Button style={{ marginRight: '1rem' }} secondary onClick={cancel}>Cancel</Button>
        <Button onClick={() => saveFeature({ description: description, icon: selectedIcon })}>Save Feature</Button>
      </div>
    </div>
  )
}

FeatureInput.propTypes = {
  defaultIcon: PropTypes.string,
  defaultDescription: PropTypes.string,
  saveFeature: PropTypes.func,
  cancel: PropTypes.func
}

export default FeatureInput
