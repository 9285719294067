import { createSlice } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'
import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'orders',
  initialState: {
    error: false,
    loading: false,
    paymentsLoading: false,
    byId: {},
    paymentsById: {}
  },
  reducers: {
    REQUEST_ORDER (state, { payload }) {
      state.loading = true
    },
    REQUEST_ORDERS (state, { payload }) {
      state.loading = true
    },
    REQUEST_PAYMENTS (state, { payload }) {
      state.paymentsLoading = true
    },
    REQUEST_ORDER_SUCCESS (state, { payload }) {
      state.loading = false
      if (payload && payload._id) {
        state.byId[payload._id] = payload
      }
    },
    REQUEST_ORDERS_SUCCESS (state, { payload }) {
      state.loading = false
      state.byId = keyBy(payload, '_id')
    },
    REQUEST_PAYMENTS_SUCCESS (state, { payload }) {
      state.paymentsLoading = false
      state.paymentsById = { ...state.paymentsById, [payload.orderID]: payload.payments }
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    }
  }
})

function * onRequestOrder ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/subscriptions/${payload}`
    )
    yield put(actions.REQUEST_ORDER_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestPayments ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/subscriptions/${payload}/payments`
    )
    yield put(actions.REQUEST_PAYMENTS_SUCCESS({ orderID: payload, payments: data }))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestOrders () {
  try {
    const { data } = yield call(
      API.get, '/subscriptions'
    )
    yield put(actions.REQUEST_ORDERS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('orders/REQUEST_ORDER', onRequestOrder)
  yield takeLatest('orders/REQUEST_PAYMENTS', onRequestPayments)
  yield takeLatest('orders/REQUEST_ORDERS', onRequestOrders)
  yield takeLatest('orders/REQUEST_FAILED', onRequestFailed)
}

export const { REQUEST_ORDER, REQUEST_ORDERS, REQUEST_PAYMENTS } = actions

export default reducer
