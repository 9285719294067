import React, { useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './OrderList.module.scss'
import Table from '../Table'
import Button from '../Button'
import StatusDisplay from '../StatusDisplay'

const RowAction = ({ _id }) => (
  <Link to={`/orders/${_id}`}>
    <Button onClick={() => false} outline size='small'>View</Button>
  </Link>
)

const NameDisplay = ({ customer: { firstName, lastName, companyName } = {} }) => firstName ? `${firstName} ${lastName}` : companyName

const DateDisplay = ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY')

const OrderList = ({ loading, requestOrders, orders }) => {
  const cols = [
    {
      title: 'Order Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: DateDisplay
    },
    {
      title: 'Order Number',
      dataIndex: '_id',
      key: '_id'
    },
    {
      title: 'Plan',
      dataIndex: 'plan.name',
      key: 'plan.name'
    },
    {
      title: 'Customer',
      dataIndex: 'customer.firstName',
      key: 'customer.name',
      render: NameDisplay
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: StatusDisplay
    },
    {
      title: '',
      key: 'actions',
      render: RowAction
    }
  ]
  useEffect(() => {
    requestOrders()
  }, [requestOrders])
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <h1 className={styles.title}>Orders</h1>
      </div>
      <div className={styles.tableWrap}>
        <Table
          defaultSortIndex='createdAt'
          defaultSortDir="desc"
          columns={cols}
          data={orders}
          loading={loading}
        />
      </div>
    </div>
  )
}

OrderList.defaultProps = {
  orders: []
}

OrderList.propTypes = {
  loading: PropTypes.bool,
  orders: PropTypes.arrayOf(PropTypes.object),
  requestOrders: PropTypes.func
}

RowAction.propTypes = {
  _id: PropTypes.string
}

DateDisplay.propTypes = {
  createdAt: PropTypes.date
}

NameDisplay.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyname: PropTypes.string
}

export default OrderList
