import React from 'react'
import 'normalize.css'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import PropTypes from 'prop-types'

import authReducer, { saga as authSaga } from './ducks/auth'
import plansReducer, { saga as plansSaga } from './ducks/plans'
import productsReducer, { saga as productsSaga } from './ducks/products'
import installationsReducer, { saga as installationsSaga } from './ducks/installations'
import statsReducer, { saga as statsSaga } from './ducks/stats'
import messageReducer from './ducks/messages'
import usersReducer, { saga as usersSaga } from './ducks/users'
import ordersReducer, { saga as ordersSaga } from './ducks/orders'

// Redux/Saga

const sagaMiddleware = createSagaMiddleware()

function * rootSaga () {
  yield all([
    authSaga(),
    usersSaga(),
    ordersSaga(),
    statsSaga(),
    plansSaga(),
    productsSaga(),
    installationsSaga()
  ])
}

const middleware = [
  ...getDefaultMiddleware(),
  sagaMiddleware
]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger)
}
const store = configureStore({
  reducer: {
    auth: authReducer,
    stats: statsReducer,
    messages: messageReducer,
    users: usersReducer,
    plans: plansReducer,
    orders: ordersReducer,
    products: productsReducer,
    installations: installationsReducer
  },
  middleware
})

sagaMiddleware.run(rootSaga)

const ReduxStore = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
)

ReduxStore.propTypes = {
  children: PropTypes.node
}

export default ReduxStore
