import { connect } from 'react-redux'
import { REQUEST_ORDERS } from '../ducks/orders'
import OrderList from '../components/OrderList'

const mapStateToProps = state => {
  return {
    loading: state.orders.loading,
    orders: Object.values(state.orders.byId).filter(({ status }) => status !== 'PENDING_MANDATE')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestOrders: () => dispatch(REQUEST_ORDERS())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)
