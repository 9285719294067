import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import DatePicker from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

import { FiArrowLeft } from 'react-icons/fi'

import { installationPropType } from '../../types'
import Button from '../Button'
import StatusDisplay from '../StatusDisplay'
import styles from './ViewInstallation.module.scss'
import InstallationDetails from '../InstallationDetails'

const ViewInstallation = ({ loading, updating, requestInstallation, installationsById, update, history }) => {
  const { installationID } = useParams()
  const [installationDate, setInstallationDate] = useState(installationsById[installationID] ? new Date(installationsById[installationID].date) : null)

  useEffect(() => {
    if (installationID) {
      requestInstallation(installationID)
    }
  }, [installationID, requestInstallation])

  if (loading || (installationID && !installationsById[installationID])) return ''

  const installation = installationsById[installationID] || {}

  const updateInstallationDate = () => {
    if (installationDate) {
      update({
        _id: installationID,
        date: installationDate,
        status: 'BOOKED'
      })
    }
  }

  const markAsDone = () => {
    update({
      _id: installationID,
      status: 'COMPLETED'
    })
  }

  const reschedule = () => {
    update({
      _id: installationID,
      status: 'CANCELLED'
    })
  }

  return (
    <div className={styles.root}>
      <Link to="/installations"><FiArrowLeft /> back</Link>
      <h1 className={styles.title}>Installation for {installation.customer.firstName ? `${installation.customer.firstName} ${installation.customer.lastName}` : installation.customer.companyName}</h1>
      <h4><StatusDisplay status={installation.status} /></h4>
      <div className={styles.container}>
        { installation.status !== 'COMPLETED' && <div className={styles.controls}>
          {
            installation.status === 'NEEDS BOOKING' || installation.status === 'CANCELLED'
              ? (
                <React.Fragment>
                  <h4>Book a time</h4>
                  <DatePicker
                    input={false}
                    value={installationDate}
                    onChange={date => setInstallationDate(date)}
                    timeFormat="HH:mm"
                  />
                  <Button loading={updating} onClick={updateInstallationDate} style={{ marginTop: '2rem', width: '100%' }} size="small">Confirm Booking</Button>
                </React.Fragment>
              )
              : (
                <React.Fragment>
                  <Button style={{ width: '100%', marginBottom: '1rem' }} loading={updating} onClick={markAsDone}>Mark as done</Button>
                  <Button style={{ width: '100%' }} secondary loading={updating} onClick={reschedule}>Reschedule</Button>
                </React.Fragment>
              )
          }
        </div>}
        <InstallationDetails installation={installation} />
      </div>
    </div>
  )
}

ViewInstallation.propTypes = {
  loading: PropTypes.bool,
  installationsById: PropTypes.objectOf(installationPropType),
  updating: PropTypes.bool,
  requestInstallation: PropTypes.func,
  insert: PropTypes.func,
  update: PropTypes.func
}

export default ViewInstallation
