import { connect } from 'react-redux'
import { REQUEST_USERS } from '../ducks/users'
import { REQUEST_PASSWORD_RESET } from '../ducks/auth'
import UserList from '../components/UserList'

const mapStateToProps = state => {
  return {
    loading: state.users.loading,
    passwordResetLoading: state.auth.loading,
    users: Object.values(state.users.byId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestUsers: () => dispatch(REQUEST_USERS()),
    resetPassword: email => dispatch(REQUEST_PASSWORD_RESET(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
