import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FiCheckCircle } from 'react-icons/fi'

import Loadingblock from '../Loadingblock'
import { paymentPropType } from '../../types'
import styles from './BillingIssues.module.scss'

const BillingIssues = ({ loading, resolve, issues }) => {
  if (loading) {
    return (
      <div className={styles.statRoot}>
        <Loadingblock />
        <Loadingblock />
      </div>
    )
  }
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Billing Issues <span role="img" aria-label="Shocked Face">😱</span></h1>
      {issues.length
        ? issues.map(issue => (
          <div className={styles.row} key={issue._id}>
            <div className={styles.icon}>
              <FiCheckCircle onClick={() => resolve(issue._id)} />
            </div>
            <div className={styles.wrap}>
              <div className={styles.grid}>
                <span>Customer:</span>
                <span>
                  {issue.subscription.customer.firstName ? `${issue.subscription.customer.firstName} ${issue.subscription.customer.lastName}` : issue.subscription.customer.companyName}
                </span>
                <span>
                  Email:
                </span>
                <span>
                  {issue.subscription.customer.email}
                </span>
                <span>
                  Plan:
                </span>
                <span>
                  {issue.subscription.plan.name}
                </span>
                <span>Due on:</span>
                <span>
                  {moment(issue.createdAt).format('DD/MM/YY')}
                </span>
                <span>Reason:</span>
                <span>
                  {issue.failureReason}
                </span>
              </div>
            </div>
          </div>
        ))
        : (
          <div className={styles.empty}>
            <img src="/superhero.svg" alt="Superhero" className={styles.emptyImage}/>
            <h2 className={styles.emptyTitle}>Nailing it!</h2>
            <p className={styles.emptyText}>
              Looks like there&apos;s no issues at the moment.
            </p>
          </div>
        )
      }
    </div>
  )
}

BillingIssues.propTypes = {
  resolve: PropTypes.func,
  loading: PropTypes.bool,
  issues: PropTypes.arrayOf(paymentPropType)
}

export default BillingIssues
