import React from 'react'
import find from 'lodash/find'
import moment from 'moment'
import { ResponsiveContainer, LineChart, Line, XAxis, Tooltip } from 'recharts'
import PropTypes from 'prop-types'

import styles from './CustomerChart.module.scss'

const CustomerChart = ({ data }) => {
  const currentMonth = new Date().getMonth()
  const formedData = new Array(6).fill(0).map((_, i) => {
    const month = currentMonth - i
    const monthData = find(data, { _id: month + 1 })
    return {
      month: moment(new Date().setMonth(month)).format('MMM'),
      value: (monthData ? monthData.total : 0)
    }
  }).reverse()
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Monthly Sales</h2>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          width={500}
          height={300}
          data={formedData}
        >
          <XAxis dataKey="month" />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#6e3dff" name='New Customers' />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

CustomerChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}

export default CustomerChart
