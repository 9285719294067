import { createSlice } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'
import { SHOW_MESSAGE } from './messages'
import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'products',
  initialState: {
    error: false,
    loading: true,
    updating: false,
    deleting: false,
    byId: {}
  },
  reducers: {
    REQUEST_PRODUCT (state, { payload }) {
      state.loading = true
    },
    REQUEST_PRODUCTS (state, { payload }) {
      state.loading = true
    },
    REQUEST_PRODUCT_SUCCESS (state, { payload }) {
      if (payload && payload._id) {
        state.byId[payload._id] = payload
      }
      state.loading = false
    },
    REQUEST_PRODUCTS_SUCCESS (state, { payload }) {
      state.byId = keyBy(payload, '_id')
      state.loading = false
    },
    INSERT_PRODUCT (state, { payload }) {
      state.updating = true
    },
    UPDATE_PRODUCT (state, { payload }) {
      state.updating = true
    },
    DELETE_PRODUCT (state, { payload }) {
      state.deleting = true
    },
    UPDATE_PRODUCT_SUCCESS (state, { payload }) {
      state.updating = false
      state.byId[payload._id] = payload
    },
    DELETE_PRODUCT_SUCCESS (state, { payload }) {
      state.deleting = false
      delete state.byId[payload]
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    }
  }
})

function * onRequestProduct ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/products/${payload}`
    )
    yield put(actions.REQUEST_PRODUCT_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestProducts ({ payload }) {
  try {
    const { data } = yield call(
      API.get, '/products'
    )
    yield put(actions.REQUEST_PRODUCTS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onInsertProduct ({ payload }) {
  try {
    const { data } = yield call(
      API.post, '/products', payload.product
    )
    yield put(actions.UPDATE_PRODUCT_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Product created' }))
    yield call(payload.redirect)
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onUpdateProduct ({ payload }) {
  try {
    const { data } = yield call(
      API.patch, `/products/${payload.productID}`, payload.product
    )
    yield put(actions.UPDATE_PRODUCT_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Product updated' }))
    yield call(payload.redirect)
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onDeleteProduct ({ payload }) {
  try {
    const { data } = yield call(
      API.delete, `/products/${payload}`, payload
    )
    yield put(actions.DELETE_PRODUCT_SUCCESS(data))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Product deleted' }))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('products/REQUEST_PRODUCT', onRequestProduct)
  yield takeLatest('products/REQUEST_PRODUCTS', onRequestProducts)
  yield takeLatest('products/INSERT_PRODUCT', onInsertProduct)
  yield takeLatest('products/UPDATE_PRODUCT', onUpdateProduct)
  yield takeLatest('products/DELETE_PRODUCT', onDeleteProduct)
  yield takeLatest('products/REQUEST_FAILED', onRequestFailed)
}

export const { REQUEST_PRODUCT, REQUEST_PRODUCTS, INSERT_PRODUCT, UPDATE_PRODUCT, DELETE_PRODUCT } = actions

export default reducer
