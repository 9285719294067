import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './StatusDisplay.module.scss'

const StatusDisplay = ({ status }) => {
  const statuses = {
    PENDING_MANDATE: styles.yellowBadge,
    ACTIVE: styles.greenBadge,
    SUSPENDED: styles.yellowBadge,
    CANCELLED: styles.redBadge,
    'NEEDS BOOKING': styles.yellowBadge,
    BOOKED: styles.blueBadge,
    COMPLETED: styles.greenBadge,
    CANCELLED: styles.redBadge
  }
  const classes = classnames(
    styles.root,
    statuses[status]
  )
  return <span className={classes}>{status.replace('_', ' ')}</span>
}

StatusDisplay.propTypes = {
}

export default StatusDisplay
