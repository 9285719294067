import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Loadingblock from '../Loadingblock'
import { subscriptionPropType } from '../../types'
import styles from './RecentCustomers.module.scss'

const RecentCustomers = ({ loading, signups }) => {
  if (loading) {
    return (
      <div className={styles.statRoot}>
        <Loadingblock />
        <Loadingblock />
      </div>
    )
  }
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Recent sales <span role="img" aria-label="Cheering">🙌</span></h1>
      {signups.length
        ? signups.map(s => (
          <div className={styles.row} key={s._id}>
            <span title={moment(s.createdAt).format('DD/MM/YY HH:mm')} className={styles.date}>{moment(s.createdAt).fromNow()}</span>
            <div>
              <span className={styles.name}>{s.customer.firstName} {s.customer.lastName}</span> <span className={styles.text}>signed up for</span> <span className={styles.plan}>{s.plan.name}</span>
            </div>
          </div>
        ))
        : (
          <div className={styles.empty}>
            <img src="/get_ready.svg" alt="Get Ready" className={styles.emptyImage}/>
            <h2 className={styles.emptyTitle}>On your marks, get set..</h2>
            <p className={styles.emptyText}>
              You&apos;re all set. Go get some sales!
            </p>
          </div>
        )
      }
    </div>
  )
}

RecentCustomers.propTypes = {
  loading: PropTypes.bool,
  signups: PropTypes.arrayOf(subscriptionPropType)
}

export default RecentCustomers
