import React from 'react'
import PropTypes from 'prop-types'

import Loadingblock from '../Loadingblock'
import styles from './Overview.module.scss'

const Stat = ({ title, value, loading }) => {
  if (loading) {
    return (
      <div className={styles.statRoot}>
        <Loadingblock />
        <Loadingblock />
      </div>
    )
  }
  return (
    <div className={styles.statRoot}>
      <h2 className={styles.value}>{value}</h2>
      <h3 className={styles.title}>{title}</h3>
    </div>
  )
}

Stat.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.number,
  title: PropTypes.string
}

const Overview = ({ loading, totalCustomers, monthlyRevenue, outstandingInstallations }) => {
  return (
    <div className={styles.root}>
      <Stat title={`Total Customer${totalCustomers === 1 ? '' : 's'}`} value={totalCustomers} loading={loading} />
      <Stat title="Monthly Revenue" value={`£${monthlyRevenue / 100}`} loading={loading} />
      <Stat title="Waiting for Installation" value={outstandingInstallations} loading={loading} />
    </div>
  )
}

Overview.propTypes = {
  loading: PropTypes.bool,
  totalCustomers: PropTypes.number,
  monthlyRevenue: PropTypes.number,
  outstandingInstallations: PropTypes.number
}

export default Overview
