import { connect } from 'react-redux'
import { REQUEST_ORDER } from '../ducks/orders'
import ViewOrder from '../components/ViewOrder'

const mapStateToProps = state => {
  return {
    loading: state.orders.loading,
    ordersById: state.orders.byId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestOrder: orderID => dispatch(REQUEST_ORDER(orderID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder)
