import { connect } from 'react-redux'
import { REQUEST_INSTALLATIONS } from '../ducks/installations'
import InstallationList from '../components/InstallationList'

const mapStateToProps = state => {
  return {
    loading: state.installations.loading,
    installations: Object.values(state.installations.byId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestInstallations: () => dispatch(REQUEST_INSTALLATIONS())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationList)
