import React, { useEffect, Fragment } from 'react'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'

import styles from './UserView.module.scss'

const UserView = ({ usersById, requestUser, loading }) => {
  const { userID } = useParams()
  const user = usersById[userID]

  useEffect(() => {
    if (userID) {
      requestUser(userID)
    }
  }, [userID, requestUser])

  if (loading || !user) return ''

  return (
    <div className={styles.root}>
      <Link to="/users"><FiArrowLeft /> back</Link>
      <h1 className={styles.name}>{user.companyName ? user.companyName : `${user.firstName} ${user.lastName}`}</h1>
      <div className={styles.detailsWrapper}>
        <p className={styles.details}>
          <span>Joined:</span>
          <span>{moment(user.createdAt).format('Do MMMM, YYYY')}</span>
        </p>
        <p className={styles.details}>
          <span>Email:</span>
          <span>{user.email}</span>
        </p>
        <p className={styles.details}>
          <span>Phone:</span>
          <span>{user.phone || 'none provided'}</span>
        </p>
        <p className={styles.details}>
          <span>Address:</span>
          <span>
            {user.address.line1},<br/>
            {user.address.line2 && (<Fragment>{user.address.line2},<br /></Fragment>)}
            {user.address.line3 && (<Fragment>{user.address.line3},<br /></Fragment>)}
            {user.address.city},<br/>
            {user.address.region && (<Fragment>{user.address.region},<br/></Fragment>)}
            {user.address.postcode}
          </span>
        </p>
      </div>
    </div>
  )
}

export default UserView
