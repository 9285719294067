import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import remove from 'lodash/remove'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'stats',
  initialState: {
    error: false,
    loading: {
      overview: true,
      recentSignups: true,
      customersPerMonth: true,
      billingIssues: true
    },
    overview: {
      totalCustomers: null,
      monthlyRevenue: null,
      outstandingInstallations: null
    },
    recentSignups: [],
    customersPerMonth: [],
    billingIssues: []
  },
  reducers: {
    REQUEST_OVERVIEW (state, { payload }) {
      state.loading.overview = true
    },
    REQUEST_OVERVIEW_SUCCESS (state, { payload }) {
      state.loading.overview = false
      state.overview = payload
    },
    REQUEST_RECENT_SIGNUPS (state, { payload }) {
      state.loading.recentSignups = true
    },
    REQUEST_RECENT_SIGNUPS_SUCCESS (state, { payload }) {
      state.loading.recentSignups = false
      state.recentSignups = payload.recentSignups
    },
    REQUEST_BILLING_ISSUES (state, { payload }) {
      state.loading.billingIssues = true
    },
    REQUEST_BILLING_ISSUES_SUCCESS (state, { payload }) {
      state.loading.billingIssues = false
      state.billingIssues = payload.billingIssues
    },
    REQUEST_MONTHLY_SIGNUPS (state, { payload }) {
      state.loading.customersPerMonth = true
    },
    REQUEST_MONTHLY_SIGNUPS_SUCCESS (state, { payload }) {
      state.loading.customersPerMonth = false
      state.customersPerMonth = payload.customersPerMonth
    },
    RESOLVE_PAYMENT (state, { payload }) {
    },
    RESOLVE_PAYMENT_SUCCESS (state, { payload }) {
      remove(state.billingIssues, n => n._id === payload)
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    }
  }
})

function * onRequestOverview () {
  try {
    const { data } = yield call(
      API.get, '/stats/overview'
    )
    yield put(actions.REQUEST_OVERVIEW_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestRecentSignups () {
  try {
    const { data } = yield call(
      API.get, '/stats/recentsignups'
    )
    yield put(actions.REQUEST_RECENT_SIGNUPS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestBillingIssues () {
  try {
    const { data } = yield call(
      API.get, '/stats/billingissues'
    )
    yield put(actions.REQUEST_BILLING_ISSUES_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onResolvePayment ({ payload }) {
  try {
    yield call(
      API.post, `/payment/${payload}/resolve`
    )
    yield put(actions.RESOLVE_PAYMENT_SUCCESS(payload))
    yield put(SHOW_MESSAGE({ status: 'success', title: 'Issue marked as resolved' }))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestMonthlyCustomers () {
  try {
    const { data } = yield call(
      API.get, '/stats/monthlycustomers'
    )
    yield put(actions.REQUEST_MONTHLY_SIGNUPS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('stats/REQUEST_OVERVIEW', onRequestOverview)
  yield takeLatest('stats/REQUEST_RECENT_SIGNUPS', onRequestRecentSignups)
  yield takeLatest('stats/REQUEST_BILLING_ISSUES', onRequestBillingIssues)
  yield takeLatest('stats/REQUEST_MONTHLY_SIGNUPS', onRequestMonthlyCustomers)
  yield takeLatest('stats/RESOLVE_PAYMENT', onResolvePayment)
  yield takeLatest('stats/REQUEST_FAILED', onRequestFailed)
}

export const { RESOLVE_PAYMENT, REQUEST_OVERVIEW, REQUEST_RECENT_SIGNUPS, REQUEST_BILLING_ISSUES, REQUEST_MONTHLY_SIGNUPS } = actions

export default reducer
