import { connect } from 'react-redux'
import { REQUEST_PAYMENTS } from '../ducks/orders'
import PaymentHistory from '../components/PaymentHistory'

const mapStateToProps = (state, { orderID }) => {
  return {
    loading: state.orders.paymentsLoading,
    payments: state.orders.paymentsById[orderID] || []
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestPayments: subscriptionID => dispatch(REQUEST_PAYMENTS(subscriptionID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory)
