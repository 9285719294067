import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import * as icons from 'react-icons/io'

import { planPropType, extraPropType } from '../../types'
import styles from './PlanForm.module.scss'
import FeatureInput from './FeatureInput'
import Form, { Field } from '../Form'
import Input from '../Input'
import Checkbox from '../Checkbox'
import Select from '../Select'
import Button from '../Button'
import Modal from '../Modal'

const networkTypes = ['FTTP', 'FTTC', 'ADSL2PLUS', 'ADSL2PLUSANNEXM', 'GFAST', 'SOGEA']

const PlanForm = ({ loading, updating, requestPlan, requestProducts, plansById, insert, update, history, productsById }) => {
  const { planID } = useParams()
  const [features, setFeatures] = useState(planID && plansById[planID] ? plansById[planID].features : [])
  const [editingFeature, editFeature] = useState(null)

  useEffect(() => {
    requestProducts()
    if (planID) {
      requestPlan(planID)
    }
  }, [requestPlan, planID, requestProducts])

  useEffect(() => {
    if (planID && plansById[planID]) {
      setFeatures(plansById[planID].features)
    }
  }, [plansById, planID])

  if (loading || (planID && !plansById[planID])) return ''

  const plan = plansById[planID] || {}
  const redirect = () => history.push('/plans')

  const onSubmit = ({ values, valid }) => {
    if (!valid) return
    values.inactive = !values.active
    values.monthlyCost = values.monthlyCost * 100
    values.installCost = values.installCost * 100
    values.features = features
    values.requiresInstall = !!values.requiresInstall
    values.extras = values.extras.map(e => typeof e === 'object' ? e.value : e)
    values.networkTypes = values.networkTypes.map(e => typeof e === 'object' ? e.value : e)
    delete values.active
    if (planID) {
      update({ plan: values, planID, redirect })
    } else {
      insert({ plan: values, redirect })
    }
  }

  const updateFeature = feature => {
    const featuresCopy = [...features]
    if (editingFeature < 0) {
      featuresCopy.push(feature)
    } else {
      featuresCopy[editingFeature] = feature
    }
    setFeatures(featuresCopy)
    editFeature(null)
  }

  const deleteFeature = index => {
    editFeature(null)
    const featuresCopy = [...features]
    featuresCopy.splice(index, 1)
    setFeatures(featuresCopy)
  }

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{planID ? 'Updating' : 'Add a new'} plan</h1>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <div className={styles.features}>
            <h2 className={styles.featuresTitle}>Plan Features</h2>
            {features.map((feature, i) => {
              const Icon = icons[feature.icon]
              return (
                <div key={feature.description || i} className={styles.feature}>
                  <Icon />
                  {feature.description}
                  <span className={styles.editFeature} onClick={() => editFeature(i)}>Edit</span>
                  <span className={styles.deleteFeature} onClick={() => deleteFeature(i)}>Remove</span>
                </div>
              )
            })}
            <div className={styles.addFeature} onClick={() => editFeature(-1)}>Add Feature</div>
            <Modal visible={editingFeature !== null} onHide={() => editFeature(null)} hideCloseButton>
              <FeatureInput
                defaultDescription={editingFeature !== null && editingFeature > -1 ? features[editingFeature].description : ''}
                defaultIcon={editingFeature !== null && editingFeature > -1 ? features[editingFeature].icon : ''}
                saveFeature={feature => updateFeature(feature)}
                cancel={() => editFeature(null)}
              />
            </Modal>
          </div>
        </div>
        <Form onSubmit={onSubmit} className={styles.form}>
          <Field label="Plan Name" required>
            <Input
              type="text"
              size="large"
              placeholder="What should your plan be called?"
              name="name"
              defaultValue={planID ? plan.name : ''} />
          </Field>
          <Field label="Indicated Download Speed" required hint="We'll either show this speed, or the estimated speed if it is lower">
            <Input
              type="text"
              placeholder="e.g. 20"
              tabAfter="mb/s"
              name="maxDownload"
              defaultValue={planID ? plan.maxDownload : ''} />
          </Field>
          <Field label="Indicated Upload Speed" required hint="We'll either show this speed, or the estimated speed if it is lower">
            <Input
              type="text"
              placeholder="e.g. 2"
              tabAfter="mb/s"
              name="maxUpload"
              defaultValue={planID ? plan.maxUpload : ''} />
          </Field>
          <Field label="Minmum Contract Length" required >
            <Input
              type="number"
              placeholder="e.g. 12"
              name="contractLength"
              tabAfter="months"
              defaultValue={planID ? plan.contractLength : ''} />
          </Field>
          <Field label="Website Link" validations={['isURL']} >
            <Input
              type="text"
              placeholder="e.g. https://gamingbroadband.com/products/gb-lite"
              name="websiteLink"
              defaultValue={planID ? plan.websiteLink : ''} />
          </Field>
          <Field label="Monthly Price" required validations={['isCurrency']} >
            <Input
              type="tel"
              placeholder="e.g. 14.99"
              name="monthlyCost"
              tabBefore='£'
              tabAfter='per month'
              defaultValue={planID && plan.monthlyCost ? plan.monthlyCost / 100 : ''} />
          </Field>
          <Field label="Installation Price" validations={['isCurrency']} >
            <Input
              type="tel"
              placeholder="e.g. 25.00"
              name="installCost"
              tabBefore='£'
              defaultValue={planID && plan.installCost ? plan.installCost / 100 : 0} />
          </Field>
          <Field
            label="Supported Networks"
            required
            hint="This will determine what plans are shown in the availability checker"
          >
            <Select
              name="networkTypes"
              style={{ width: '100%' }}
              options={networkTypes.map(t => ({ text: t, value: t }))}
              multiple
              searchable
              defaultValue={planID && plan ? plan.networkTypes.map(t => ({ text: t, value: t })) : []}
            />
          </Field>
          <Field
            label="Extras"
            hint="Any products available for purchase when signing up to this plan (e.g. routers). Manage these in Products & Extras"
          >
            <Select
              name="extras"
              style={{ width: '100%' }}
              options={Object.values(productsById).map(p => ({ text: p.name, value: p._id }))}
              multiple
              searchable
              defaultValue={planID && plan ? plan.extras.map(p => ({ text: p.name, value: p._id })) : []}
            />
          </Field>
          <Field label="Footnotes">
            <Select
              name="notes"
              style={{ width: '100%' }}
              options={planID ? plan.notes.map(n => ({ text: n, value: n })) : []}
              allowCustom
              searchable
              multiple
              defaultValue={planID ? plan.notes.map(n => ({ text: n, value: n })) : []}
            />
          </Field>
          <Field label="Installation Required?" >
            <Checkbox name="requiresInstall" defaultChecked={planID && plan.requiresInstall}>
              <span className={styles.hint}>This plan requires installation</span>
            </Checkbox>
          </Field>
          <Field label="Plan is active?" >
            <Checkbox name="active" defaultChecked={planID && !plan.inactive}>
              <span className={styles.hint}>This plan is available for purchase</span>
            </Checkbox>
          </Field>
          <Button loading={updating} type="submit">{planID ? 'Save Changes' : 'Save plan'}</Button>
        </Form>
      </div>
    </div>
  )
}

PlanForm.propTypes = {
  loading: PropTypes.bool,
  productsById: PropTypes.objectOf(extraPropType),
  updating: PropTypes.bool,
  plansById: PropTypes.objectOf(planPropType),
  requestPlan: PropTypes.func,
  requestProducts: PropTypes.func,
  insert: PropTypes.func,
  update: PropTypes.func
}

export default PlanForm
